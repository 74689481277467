var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "backuped-branch-card",
      style: { height: "100px", width: "85vw" },
      on: { click: _vm.data.click },
    },
    [
      _c("div", { staticClass: "fw500 flex bet a-center" }, [
        _c("div", {
          style: { "margin-bottom": "10px", "margin-right": "20px" },
          domProps: {
            textContent: _vm._s(_vm.branchNameById(_vm.data.item.branch_id)),
          },
        }),
      ]),
      _c(
        "div",
        {
          staticClass: "backuped-branch-card__main",
          style: {
            display: "flex",
            "justify-content": "space-between",
            width: "100%",
            "border-bottom": "none",
          },
          on: { click: _vm.data.click },
        },
        [
          _c(
            "div",
            {
              staticClass: "backuped-branch__main_name",
              style: { display: "flex", "flex-direction": "column" },
            },
            [
              _c("div", {
                staticClass: "grey",
                domProps: { textContent: _vm._s(_vm.$t("Start time")) },
              }),
              _c("div", {
                domProps: {
                  textContent: _vm._s(_vm.data.item.from_time.slice(0, 5)),
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "backuped-branch__main_name",
              style: { display: "flex", "flex-direction": "column" },
            },
            [
              _c("div", {
                staticClass: "grey",
                domProps: { textContent: _vm._s(_vm.$t("End time")) },
              }),
              _c("div", {
                domProps: {
                  textContent: _vm._s(_vm.data.item.to_time.slice(0, 5)),
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "backuped-branch__main_name",
              style: { display: "flex", "flex-direction": "column" },
            },
            [
              _c("div", {
                staticClass: "grey",
                domProps: { textContent: _vm._s(_vm.$t("day for backup")) },
              }),
              _c("div", {
                domProps: {
                  textContent: _vm._s(_vm.datNameById(_vm.data.item.day)),
                },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }